export default {
	//金额，面积等加入千分符
	dataFormat: function (data) {
		if (data != '' && data != undefined){
			var num = parseFloat(data).toFixed(2).toString().split(".");  // 分隔小数点
			var arr = num[0].split("").reverse();  // 转换成字符数组并且倒序排列
			var res = [];
			for (var i = 0, len = arr.length; i < len; i++) {
				if (i % 3 === 0 && i !== 0) {
					res.push(",");   // 添加分隔符
				}
				res.push(arr[i]);
			}
			res.reverse(); // 再次倒序成为正确的顺序
			if (num[1]) {  // 如果有小数的话添加小数部分
				res = res.join("").concat("." + num[1]);
			} else {
				res = res.join("");
			}
			return res;
		}
	},
	formatDate: function (value) {
        let date = new Date(value);
        let y = date.getFullYear();
        let MM = date.getMonth() + 1;
        MM = MM < 10 ? ('0' + MM) : MM;
        let d = date.getDate();
        d = d < 10 ? ('0' + d) : d;
        let h = date.getHours();
        h = h < 10 ? ('0' + h) : h;
        let m = date.getMinutes();
        m = m < 10 ? ('0' + m) : m;
        let s = date.getSeconds();
        s = s < 10 ? ('0' + s) : s;
        return y + '-' + MM + '-' + d + ' ' + h + ':' + m + ':' + s;
    },
	formatDateDay: function (value) {
        let date = new Date(value);
        let y = date.getFullYear();
        let MM = date.getMonth() + 1;
        MM = MM < 10 ? ('0' + MM) : MM;
        let d = date.getDate();
        d = d < 10 ? ('0' + d) : d;
        let h = date.getHours();
        h = h < 10 ? ('0' + h) : h;
        let m = date.getMinutes();
        m = m < 10 ? ('0' + m) : m;
        let s = date.getSeconds();
        s = s < 10 ? ('0' + s) : s;
        return y + '-' + MM + '-' + d;
    },
	isDisplay: function(value){
		var permissionArr = JSON.parse(window.localStorage.getItem('permission'));
		for(var j = 0,len=permissionArr.length; j < len; j++) {
			if(value==permissionArr[j].permission){
				return true
			}
		}
		return false;
	}
}