import axios from 'axios';
import {getLocalStore} from '@/util/store'
import { MessageBox, Message,Loading } from 'element-ui';
// import qs from "qs";
// const request = axios.create({
//     baseUrl:'http://localhost:8080',
//     timeout:5000
// })
let loadingInstance = null;//loading对象
let needLoadingRequestCount = 0;//当前请求的数量
/*
* 参数
*   loading:true|false 表示是否加载loading效果
* */
/*拦截*/
axios.interceptors.request.use(
    config => {
        if(config.url!='/dms/loginByAccount'&&config.url!='/dms/serviceLogin'&&config.url!='/dms/getCaptcha'&&config.url!='/dms/getDictIndustry'&&config.url!='/dms/getVerificationCode'&&config.url!='/dms/smsLogin'&&config.url!='/dms/saveRegisterLoginInfo'&&config.url!='/dms/saveRegisterDetailedInfo'&&config.url!='/dms/forgetPwd'&&config.url!='/dms/forgetPwd'){
            if(getLocalStore('token')){
                const token = 'Bearer '+ getLocalStore('token');
                config.headers.Authorization = token;
            }else{
                window.$vue.$router.push({path: '/'})
                setTimeout(() => {
                    window.localStorage.clear();
                }, 100);
            }
            
        }
        // config.headers.ContentType = "application/x-www-form-urlencoded";
        // config.transformRequest = data => qs.stringify(data);
        return config
    },
    error => {
        loadingInstance && loadingInstance.close();
        return Promise.reject(error.response);
    }
)

axios.interceptors.response.use(

    response => {
        if(response.data.msg=='tokenMissing'||response.data.msg=='tokenExpired'||response.data.msg=='tokenIllegal'){
            window.$vue.$router.push({path: '/'})
            window.localStorage.clear();
        }
        return response;
    },
    error => {
        Message({
            message: "请求数据失败",
            type: "error",
            duration: 3 * 1000
        });
        return Promise.reject(error.response);
    }
)

export default axios
