import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
    return originalPush.call(this, location).catch(err => err)
}

const routes = [
    {path: '/', redirect: '/homePage'},
    {
        path: '/login',
        name: 'login',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '@/page/login/index.vue'),
        meta: {title: '登录页'}
    },
    {
        path: '/redirect',
        name: 'redirect',
        component: () => import(/* webpackChunkName: "about" */ '@/page/redirect/index.vue'),
        meta: {title: '跳转登录'}
    },
    {
        path: '/agreement',
        name: 'agreement',
        component: () => import('@/page/agreement/index.vue'),
        meta: {title: '云策产品服务协议'}
    },
    {
        path: '/reportStatement',
        name: 'reportStatement',
        component: () => import('@/page/reportStatement/index.vue'),
        meta: {title: '测算报告声明'}
    },
    {
        path: '/website',
        name: 'website',
        component: () => import(/* webpackChunkName: "about" */ '@/page/website/home.vue'),
        children:[
            {
                path: '/homePage',
                name: 'homePage',
                component: () => import('@/page/website/index.vue'),
                meta: {title: '云策官网'}
            },
            {
                path: '/productService',
                name: 'productService',
                component: () => import('@/page/website/productService.vue'),
                meta: {title: '产品服务'}
            },
        ]
    },
    {
        path: '/home',
        name: 'home',
        component: () => import(/* webpackChunkName: "about" */ '@/page/home/index.vue'),
        children: [
            {
                path: '/projectManagement',
                name: 'projectManagement',
                component: () => import('@/views/projectManagement/index'),
                meta: {title: '项目管理'},
                children:[
                    {
                        path: '/projectSearch',
                        name: 'projectSearch',
                        component: () => import('@/views/projectSearch/index'),
                        meta: {title: '项目查询'}
                    },
                    {
                        path: '/projectOverview',
                        name: 'projectOverview',
                        component: () => import('@/views/projectOverview/index'),
                        meta: {title: '项目概览'}
                    },
                    {
                        path: '/proMap',
                        name: 'proMap',
                        component: () => import('@/views/projectOverview/proMap'),
                        meta: {title: '项目地图'}
                    },
                    {
                        path: '/moreMap',
                        name: 'moreMap',
                        component: () => import('@/views/projectOverview/moreMap'),
                        meta: {title: '开发项目地图'}
                    },
                    {
                        path: '/tabsContents',
                        name: 'tabsContents',
                        component: () => import('@/views/projectDetails/projectDetails'),
                        meta: {title: '项目详情'},
                        children:[
                            {
                                path: '/analysisDetails',
                                name: 'analysisDetails',
                                component: () => import('@/views/analysisDetails/index'),
                                meta: {title: '分析详情'}
                            },
                            {
                                path: '/construction',
                                name: 'construction',
                                component: () => import('@/views/construction/construction.vue'),
                                meta: {title: '施工方分析'},
                            },
                            {
                                path: '/constructionDetails',
                                name: 'constructionDetails',
                                component: () => import('@/views/constructionDetails/index.vue'),
                                meta: {title: '施工方详情'},
                            },
                            {
                                path: '/portraitProject',
                                name: 'portraitProject',
                                component: () => import('@/views/portraitProject/portraitProject.vue'),
                                meta: {title: '项目画像'}
                            },
                            {
                                path: '/mapAround',
                                name: 'mapAround',
                                component: () => import('@/views/mapAround/index.vue'),
                                meta: {title: '周边情况'},
                            },
                            {
                                path: '/regional',
                                name: 'regional',
                                component: () => import('@/views/regional/index.vue'),
                                meta: {title: '区域分析'}
                            },
                            {
                                path: '/businessCost',
                                name: 'businessCost',
                                component: () => import('@/views/businessCost/businessCost.vue'),
                                meta: {title: '成本分析'},
                            },
                           
                            {
                                path: '/taxCase',
                                name: 'taxCase',
                                component: () => import('@/views/taxCase/taxCase.vue'),
                                meta: {title: '税负案例'},
                            },
                            {
                                path: '/taxCaseTable',
                                name: 'taxCaseTable',
                                component: () => import('@/views/taxCaseTable/taxCaseTable.vue'),
                                meta: {title: '税负数据案例'},
                            },
                            {
                                path: '/glsgdwfx',
                                name: 'glsgdwfx',
                                component: () => import('@/views/associatedConstruction/associatedConstruction.vue'),
                                meta: {title: '分析详情'}
                            },
                            {
                                path: '/flfxpg',
                                name: 'flfxpg',
                                component: () => import('@/views/legalRisk/tabCU'),
                                meta: {title: '建设单位与施工单位的法律纠纷'}
                            },
                            {
                                path: '/other',
                                name: 'other',
                                component: () => import('@/views/legalRisk/tabOther'),
                                meta: {title: '建设单位其他的法律纠纷'}
                            },
                            {
                                path: '/tabBuild',
                                name: 'tabBuild',
                                component: () => import('@/views/legalRisk/tabBuild'),
                                meta: {title: '本项目建设方法律纠纷'}
                            },
                            {
                                path: '/tabConstruction',
                                name: 'tabConstruction',
                                component: () => import('@/views/legalRisk/tabConstruction'),
                                meta: {title: '施工方其他法律纠纷'}
                            },
                            {
                                path: '/publicSentiment',
                                name: 'publicSentiment',
                                component: () => import('@/views/legalRisk/publicSentiment'),
                                meta: {title: '舆情风险'}
                            },
                            {
                                path: '/viewAll',
                                name: 'viewAll',
                                component: () => import('@/views/legalRisk/viewAll.vue'),
                                meta: {title: '裁定书详情'}
                            },
                            {
                                path: '/hisrotyProject',
                                name: 'hisrotyProject',
                                component: () => import('@/views/historyProject/historyProject.vue'),
                                meta: {title: '历史建设项目'}
                            },
                            {
                                path: '/landTransaction',
                                name: 'landTransaction',
                                component: () => import('@/views/analysisDetails/landTransaction/landTransaction.vue'),
                                meta: {title: '历史拿地信息'}
                            },
                            {
                                path: '/judicial',
                                name: 'judicial',
                                component: () => import('@/views/constructionDetails/judicial.vue'),
                                meta: {title: '司法详情'}
                            },
                        ]
                    },
                ]
            },
            {
                path: '/industryAnalysis',
                name: 'industryAnalysis',
                component: () => import('@/views/industryAnalysis/home.vue'),
                meta: {title: '测算报告'},
                
            },
            // {
            //     path: '/calculate',
            //     name: 'calculate',
            //     component: () => import('@/views/industryAnalysis/home.vue'),
            //     meta: {title: '区域测算'},
                
            // },
            {
                path: '/setData',
                name: 'setData',
                component: () => import('@/views/industryAnalysis/setData.vue'),
                meta: {title: '设置测算参数'},
                
            },
            {
                path: '/report',
                name: 'report',
                component: () => import('@/views/industryAnalysis/report.vue'),
                meta: {title: '预览报告'}
            },
            {
                path: '/policiesRegulation',
                name: 'policiesRegulation',
                component: () => import('@/views/policiesRegulation/index.vue'),
                meta: {title: '政策法规'},
            },
            {
                path: '/detail',
                name: 'detail',
                component: () => import('@/views/policiesRegulation/detail.vue'),
                meta: {title: '政策法规详情'}
            },
            {
                path: '/policyCaliber',
                name: 'policyCaliber',
                component: () => import('@/views/policyCaliber/index.vue'),
                meta: {title: '政策口径'},
            },
            {
                path: '/caliberDetail',
                name: 'caliberDetail',
                component: () => import('@/views/policyCaliber/detail.vue'),
                meta: {title: '政策口径详情'}
            },
            {
                path: '/onlineSignature',
                name: 'onlineSignature',
                component: () => import('@/views/onlineSignature/index.vue'),
                meta: {title: '网签数据'}
            },
            {
                path: '/osdetail',
                name: 'osdetail',
                component: () => import('@/views/onlineSignature/osdetail.vue'),
                meta: {title: '网签详情'}
            },
            {
                path: '/overview',
                name: 'overview',
                component: () => import('@/views/onlineSignature/overview/index.vue'),
                meta: {title: '网签区域概览'}
            },
            {
                path: '/personalDetails',
                name: 'personalDetails',
                component: () => import('@/views/personalDetails/index.vue'),
                meta: {title: '个人详情'}
            },
            {
                path: '/netWork',
                name: 'netWork',
                component: () => import('@/views/netWork/index.vue'),
                meta: {title: '互联网数据'}
            },
            {
                path: '/netDetail',
                name: 'netDetail',
                component: () => import('@/views/netWork/netDetail.vue'),
                meta: {title: '互联网信息'}
            },
            {
                path: '/contrast',
                name: 'contrast',
                component: () => import('@/views/netWork/contrast.vue'),
                meta: {title: '数据源对比'}
            },
            {
                path: '/landData',
                name: 'landData',
                component: () => import('@/views/landData/index.vue'),
                meta: {title: '土地交易'}
            },

        ]
    },
   
]

const router = new VueRouter({
    routes
})


export default router
