import Vue from "vue";
import Vuex, { Store } from "vuex";
import { getStore, setStore } from "@/util/store";
import createPersistedState from "vuex-persistedstate"

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    path: getStore("path") || ["项目管理"], //导航栏数组
    pathLevel: getStore("pathLevel") || 1, //判断页面级别，是否显示返回上级
    menuLsit: getStore("menuLsit") || [],//菜单列表，记录路由跳转历史
    proNameID:{},
  },
  mutations: {
    path(state,payload){
      state.path = payload
    },
    proNameID(state,payload){
      state.proNameID = payload
    },
    addPath(state, item) {
      state.path.push(item)
      state.pathLevel = state.path.length
      setStore("path", state.path)
      setStore("pathLevel", state.pathLevel)
    },
    reducePath(state) {
      state.path.splice(-1, 1);
      state.pathLevel = state.path.length;
      setStore("path", state.path);
      setStore("pathLevel", state.pathLevel);
    },
    addMenuList(state, menuName) {
      state.menuLsit.push(menuName);
      setStore("menuLsit", state.menuLsit);
    },
    reduceMenuList(state) {
      state.menuLsit.splice(-1, 1);//删除最后一个
      setStore("menuLsit", state.menuLsit);
      var len = state.menuLsit.length;
      setStore("currentMenu", state.menuLsit[len - 1]);//把新的历史菜单中的最后一个元素作为当前页
    },
    reload(state){
      state.menuLsit = [];
      state.pathLevel = 1;
      state.path = ["项目管理"];
    }
  },
  actions: {},
  modules: {},
  plugins: [
      createPersistedState({
          storage: window.sessionStorage,
          reducer(val) {
              return {
                  // 只储存state中的user
                  path: val.path
              }
          }
      })
  ]
});
