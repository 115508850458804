import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import {VueJsonp} from 'vue-jsonp'

//引入全局css文件
import '@/assets/css/main.css';

//引入路由控制文件
import '@/permission.js';

// 引入echarts
import * as echarts from 'echarts'
Vue.prototype.$echarts = echarts

// 引入axios
import axios from '@/util/request'

Vue.prototype.axios= axios
// require("@/util/request")
Vue.config.productionTip = false
// axios.defaults.baseURL = '' //生产打包

//目前生产\测试环境,都需要/api
axios.defaults.baseURL = '/api' //开发配置
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';


//引入elementui
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
//引入waterfall2瀑布流
import waterfall from "vue-waterfall2";
Vue.use(waterfall);
import pinyin  from "js-pinyin";
Vue.use(pinyin);
Vue.use(ElementUI);
Vue.config.productionTip = false
//引入jsonp跨域
Vue.use(VueJsonp);
// console.log(vueJsonp.install)

//引入iconpark图标组件
import {install} from '@icon-park/vue/es/all';
install(Vue,'i');
import '@icon-park/vue/styles/index.css';

//引入百度地图组件库中的所有组件
import BaiduMap from 'vue-baidu-map'
Vue.use(BaiduMap,{ak:'jMnfAv2MlXEUmzzDjjGvlmXrpj0TP66a'})

//引入时间格式控制组件
import moment from 'moment/moment'
Vue.filter('moment', function (value, formatString) { formatString = formatString || 'YYYY-MM-DD HH:mm:ss'; return moment(value).format(formatString);})

import utils from './util/util';
Vue.prototype.utils = utils

import md5 from 'js-md5';
Vue.prototype.$md5 = md5;

router.afterEach(( to, from, next ) => {
  setTimeout(()=>{
      var _hmt = _hmt || [];
      (function() {
        var hm = document.createElement("script");
        hm.src = "https://hm.baidu.com/hm.js?911934bc36a025ac5c2420ea0b1997ff";
        var s = document.getElementsByTagName("script")[0]; 
        s.parentNode.insertBefore(hm, s);
      })();
  },0);
});



window.$vue = new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
