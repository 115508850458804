
//localStorage存入
export const setLocalStore = (key, value) => {
    window.localStorage.setItem(key, JSON.stringify(value));
}
//获取localStorage
export const getLocalStore = (key) => {
    return JSON.parse(window.localStorage.getItem(key));
}
//sessionStorage存入
export const setStore = (key, value) => {
    window.sessionStorage.setItem(key, JSON.stringify(value));
}

//获取sessionStorage
export const getStore = (key) => {
    return JSON.parse(window.sessionStorage.getItem(key));
}
// 删除sessionStorage
export const removeStore = name => {
    window.sessionStorage.removeItem(name);
}
//清空全部sessionStorage
export const clearStore = () => {
    window.sessionStorage.clear();
    window.localStorage.clear();
}
