//处理整个系统的跳转，渲染权限在这里面进行
import router from "./router";
import store from "./store";
import NProgress from "nprogress"; // progress bar
import "nprogress/nprogress.css"; // progress bar style


NProgress.configure({ showSpinner: false }); // NProgress Configuration


router.beforeEach(async (to, from, next) => {
  // start progress bar
  NProgress.start();
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next();
});

router.afterEach(() => {
  // finish progress bar
  NProgress.done();
});
